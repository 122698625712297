<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList" style="top: 20px; bottom: -20px">
      <div class="mainList_operation clearfloat">
        <!-- 搜索 -->
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <span
              >日期选择：
              <el-date-picker
                class="search_input"
                format="yyyy-M"
                value-format="timestamp"
                v-model="parameter.date"
                type="months"
                placeholder="请选择时间">
              </el-date-picker>
            </span>
            <span
              >姓名：
              <el-input
                class="search_input"
                v-model="parameter.userName"
                clearable
                placeholder="请输入姓名" />
            </span>
            <span
              >部门：
              <el-select
                class="search_input"
                style="width: 250px"
                v-model="parameter.deptId"
                placeholder="请选择部门"
                multiple
                clearable
                filterable>
                <el-option
                  v-for="(n, k) in deptList"
                  :key="k"
                  :label="n.deptName"
                  :value="n.id"></el-option>
              </el-select>
            </span>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索
            </el-button>
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <!-- 导出按钮-->
        <div class="fr">
          <el-button type="primary" icon="el-icon-plus" @click="exportFn">导出</el-button>
        </div>
      </div>
      <!-- 表格展示 -->
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading">
            <el-table-column align="center" prop="departmentName" label="部门" />
            <el-table-column align="center" prop="staffName" label="姓名" />
            <el-table-column align="center" sortable min-width="110" prop="birthdate" label="生日">
              <template slot-scope="scope">
                {{ scope.row.birthdate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="calendar" label="生日方式">
              <template slot-scope="scope">
                {{
                  scope.row.lunar ? scope.row.lunar : scope.row.calendar | dict(dictData.calendar)
                }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="birth" sortable min-width="110" label="出生日期">
              <template slot-scope="scope">
                {{ scope.row.birth | dateFormat }}
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="total">
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize" />
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      loading: false,
      parameter: {
        //获取表格
        pageNow: 1,
        pageSize: 10,
        deptId: [],
        userName: '',
      },
      pageSize: 0, //当前页面
      tableData: [], //标签列表
      total: 0, //标签条数
      dictData: [], //字典
      deptList: [], // 部门列表
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.$api.dict.listSysDictData('STAFF_BIRTHDAY', true).then(res => {
      this.dictData.calendar = res.data
    })
    this.getDeptList()
    this.getData() //获取生日列表
  },
  methods: {
    // 获取生日列表
    getData() {
      this.loading = true

      if (!this.parameter.date || !this.parameter.date[0]) {
        this.$set(this.parameter, 'date', [this.monthStartTimestamp()])
      }
      if (!this.parameter.deptId || !this.parameter.deptId[0]) {
        delete this.parameter.deptId
      }

      this.$api.user
        .getBirthday(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data == []) {
            this.tableData = res.data
          } else {
            this.tableData = res.data.records
            this.total = res.data.total
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    //获得本月开始时间戳
    monthStartTimestamp() {
      let now = new Date()
      let nowYear = now.getFullYear() //当前年
      let nowMonth = now.getMonth() //当前月
      let firstDay = new Date(nowYear, nowMonth, 1).getTime() //本月开始时间戳
      return firstDay
    },

    /** 加载 "部门" 列表数据 **/
    getDeptList() {
      this.$api.sysDept
        .listDept()
        .then(res => {
          this.deptList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 表格行数
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },

    // 换页
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.parameter.pageNow = 1
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    //导出
    exportFn() {
      if (!this.parameter.date || !this.parameter.date[0]) {
        this.parameter.date = []
        this.parameter.date[0] = Date.parse(new Date())
      }
      if (!this.parameter.deptId || !this.parameter.deptId[0]) {
        delete this.parameter.deptId
      }

      this.$api.user
        .exportBirthday(this.parameter)
        .then(res => {
          var blob = new Blob([res], { type: 'application/vnd.ms-excel' })
          var url = URL.createObjectURL(blob)
          var exportLink = document.createElement('a')
          exportLink.setAttribute('download', '员工生日.xlsx')
          exportLink.href = url
          document.body.appendChild(exportLink)
          exportLink.click()
          document.body.removeChild(exportLink) //下载完成移除元素
          URL.revokeObjectURL(exportLink.href) //释放掉blob对象
          this.$message({
            message: '导出成功!',
            type: 'success',
          })
        })
        .catch(err => {
          console.log(err)
        })
    },

    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.date = ''
      this.parameter.userName = ''
      this.parameter.deptId = ''
      this.pageChangeHandler(1)
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.mainList_operation {
  margin-top: 20px;
}

.search_input {
  width: 250px;
  margin-right: 10px;
}

/deep/ .el-table .cell {
  /* 表格换行 */
  white-space: pre-wrap;
  /* 信息太多，缩小间距 */
  padding: 6px 3px;
}

.btn_danger {
  padding-left: 5px;
}

.dialog_table {
  margin: 10px 1px;
  width: 700px;
}

.mainList_operation {
  margin-top: 0px;
}

/deep/ .fullScreen.show {
  padding-top: 1000px;
}
</style>
<style>
.el-picker-panel__footer .el-button {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
}

.el-picker-panel__footer .el-button:focus,
.el-picker-panel__footer .el-button:hover {
  background: #66b1ff;
  border-color: #66b1ff;
  color: #fff;
}

.el-month-table td.today .cell {
  font-weight: 500;
  color: #606266;
}
</style>
